<template>
    <v-container>
        <v-card class="mb-10">
            <v-row align="center">
                <v-col cols=9>
                    <v-card-title class="card-title">Φόρμα Εσόδου</v-card-title>
                    <v-card-subtitle style="color: red">{{ this.$route.query.copy ? '(αντίγραφο)' : '' }} </v-card-subtitle>
                </v-col>
                <v-col cols=3 align="end" class="pr-5">
                    <v-btn v-if="this.$route.query.copy" outlined @click="$router.go(-1)"> <v-icon> mdi-arrow-left</v-icon>back </v-btn>
                    <v-btn v-else outlined @click="createCopy()"> make copy </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6">
                    <v-autocomplete
                        v-model="selectedChargeUnitsByLevel[cuLevel]"
                        :items="getFilteredChargeUnits()"
                        :item-text="item => `${item.title}(${item.code})`"
                        placeholder="Επέλεξε Μονάδα Χρέωσης"
                        :label="getChargeUnitPath()"
                        hide-details
                        return-object
                        :menu-props="{closeOnContentClick:true}"
                        @change="setCuLevel"
                    >
                        <template v-slot:prepend-inner>
                            <v-btn v-if="cuLevel >= 1 && Object.keys(selectedChargeUnitsByLevel[1]).length > 0" icon small dark color="black" @click="clearChargeUnitPath">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-autocomplete
                        v-model="selectedCategoriesByLevel[level]"
                        :items="getFilteredCategories()"
                        :item-text="item => `${item.title}(${item.code})`"
                        placeholder="Επέλεξε Κατηγορία"
                        :label="getCategoryPath()"
                        hide-details
                        return-object
                        :menu-props="{closeOnContentClick:true}"
                        @change="setLevel"
                    >
                        <template v-slot:prepend-inner>
                            <v-btn v-if="level >= 1 && Object.keys(selectedCategoriesByLevel[1]).length > 0" icon small dark color="black" @click="clearCategoryPath">
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="income.title"
                        label="Τίτλος"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="income.description"
                        label="Περιγραφή"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="income.amount"
                        label="Σύνολο/Μεικτά"
                        :rules="numberRules"
                        suffix="€"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="2">
                    <v-text-field
                        v-model="income.fpa"
                        label="Φόρος"
                        suffix="%"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        :value="computedCleanMoney"
                        label="Σύνολο/Καθαρά"
                        :rules="numberRules"
                        suffix="€"
                        type="number"
                        disabled
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="2">
                    <v-text-field
                        v-model="income.withholding"
                        label="Παρακράτηση"
                        suffix="%"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        :value="computedFinalMoney"
                        label="Σύνολο/Τελικό"
                        :rules="numberRules"
                        suffix="€"
                        type="number"
                        disabled
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="computedIncomeDate"
                                label="Ημερομηνία εσόδου"
                                :rules="rules"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="income.incomeDate" @input="menu = false" />
                    </v-menu>
                </v-col>
                <v-col cols="4">
                    <v-autocomplete
                        v-model="income.status"
                        :items="status"
                        :rules="rules"
                        menu-props="auto"
                        label="Κατάσταση"
                        hide-details
                        :clearable="!readOnly"
                        item-text="displayValue"
                        item-value="value"
                    />
                </v-col>
                <v-col cols="4">
                    <v-autocomplete
                        v-model="income.paymentMethod"
                        :items="paymentMethod"
                        menu-props="auto"
                        label="Μέθοδος πληρωμής"
                        hide-details
                        :clearable="!readOnly"
                        item-text="displayValue"
                        item-value="value"
                    />
                </v-col>
            </v-row>
            <v-row v-if="income.paymentMethod === 'CASH'">
                <v-col cols="8">
                    <v-text-field
                        v-model="income.incomeInfo.cashOnName"
                        label="Όνομα αναφοράς μετρητών"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row v-else-if="income.paymentMethod === 'CHECK'">
                <v-col cols="3">
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="computedCheckDate"
                                label="Ημερομηνία έκδοσης επιταγής"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="income.incomeInfo.checkDate" @input="menu2 = false" />
                    </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="computedCheckExpirationDate"
                                label="Ημερομηνία λήξης επιταγής"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="income.incomeInfo.checkExpirationDate" @input="menu3 = false" />
                    </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="income.incomeInfo.checkNumber"
                        label="Αριθμός επιταγής"
                        type="number"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="3">
                    <v-select
                        v-model="income.incomeInfo.checkBank"
                        :items="banks"
                        label="Τράπεζα"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row v-if="income.paymentMethod !== 'OFF_RECORD'">
                <v-col :cols="income.invoiceType ? 4 : 12">
                    <v-autocomplete
                        v-model="income.invoiceType"
                        :items="invoiceType"
                        menu-props="auto"
                        label="Τύπος τιμολογίου"
                        hide-details
                        :clearable="!readOnly"
                        item-text="displayValue"
                        item-value="value"
                    />
                </v-col>
                <!-- <v-col v-if="income.invoiceType" cols="4">
                    <v-text-field
                        v-model="income.incomeInfo.invoiceId"
                        label="ID τιμολογίου"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col> -->
                <v-col v-if="income.invoiceType" cols="4">
                    <v-text-field
                        v-model="income.incomeInfo.invoiceNumber"
                        label="Αριθμός τιμολογίου"
                        type="text"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-dialog
                    v-model="installmentDialog"
                    persistent
                    max-width="70%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="mx-5 my-2"
                            dark
                            small
                            color="black"
                            v-bind="attrs"
                            v-on="on"
                        > <v-icon>mdi-plus</v-icon>Προσθήκη δόσης </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Δόση</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <v-menu
                                            v-model="menu4"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    :value="computedInstallmentDate"
                                                    label="Ημερομηνία δόσης"
                                                    :rules="rules"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-date-picker v-model="installmentDate" @input="menu4 = false" />
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="installmentAmount"
                                            label="Σύνολο δόσης"
                                            :rules="numberRules"
                                            suffix="€"
                                            type="number"
                                            :clearable="clearable"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            v-model="installmentDescription"
                                            label="Περιγραφή"
                                            :clearable="clearable"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-autocomplete
                                            v-model="installmentPaymentMethod"
                                            :items="paymentMethod"
                                            menu-props="auto"
                                            :rules="rules"
                                            label="Μέθοδος πληρωμής"
                                            hide-details
                                            :clearable="!readOnly"
                                            item-text="displayValue"
                                            item-value="value"
                                            @change="resetInstallmentSpecs()"
                                        />
                                    </v-col>
                                    <v-col v-if="installmentPaymentMethod === 'CASH'" cols="6">
                                        <v-text-field
                                            v-model="installmentSpecs.cashOnName"
                                            label="Όνομα αναφοράς μετρητών"
                                            type="text"
                                            :clearable="clearable"
                                        />
                                    </v-col>
                                    <v-row v-else-if="installmentPaymentMethod === 'CHECK'">
                                        <v-col cols="3">
                                            <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="computedInstallmentCheckDate"
                                                        label="Ημερομηνία έκδοσης επιταγής"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="installmentSpecs.checkDate" @input="menu2 = false" />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-menu
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="computedInstallmentCheckExpirationDate"
                                                        label="Ημερομηνία λήξης επιταγής"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    />
                                                </template>
                                                <v-date-picker v-model="installmentSpecs.checkExpirationDate" @input="menu3 = false" />
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="installmentSpecs.checkNumber"
                                                label="Αριθμός επιταγής"
                                                type="number"
                                                :clearable="clearable"
                                            />
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-model="installmentSpecs.checkBank"
                                                label="Τράπεζα"
                                                type="text"
                                                :clearable="clearable"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="installmentPaymentMethod !== 'OFF_RECORD'">
                                        <v-col cols="6">
                                            <v-autocomplete
                                                v-model="installmentSpecs.invoiceType"
                                                :items="invoiceType"
                                                menu-props="auto"
                                                label="Τύπος τιμολογίου"
                                                hide-details
                                                :clearable="!readOnly"
                                                item-text="displayValue"
                                                item-value="value"
                                            />
                                        </v-col>
                                        <!-- <v-col v-if="income.invoiceType" cols="4">
                                            <v-text-field
                                                v-model="income.incomeInfo.invoiceId"
                                                label="ID τιμολογίου"
                                                type="text"
                                                :clearable="clearable"
                                            />
                                        </v-col> -->
                                        <v-col v-if="installmentSpecs.invoiceType" cols="6">
                                            <v-text-field
                                                v-model="installmentSpecs.invoiceNumber"
                                                label="Αριθμός τιμολογίου"
                                                type="text"
                                                :clearable="clearable"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-row>
                                <v-row justify="center">
                                    <v-btn
                                        class="mx-5 my-2 white--text"
                                        dark
                                        small
                                        color="black"
                                        :disabled="installmentAmount <= 0 || !installmentDate"
                                        @click="onInstallmentSubmit"
                                    > Καταχώριση</v-btn>
                                    <v-btn
                                        class="mx-5 my-2"
                                        dark
                                        small
                                        color="red"
                                        @click="installmentDialog = false"
                                    > Ακύρωση </v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
            <v-row v-if="hasInstallments" justify="center">
                <v-col cols="12">
                    <v-card class="my-2">
                        <v-row>
                            <v-col cols="3">
                                <v-card-title>
                                    Δόσεις
                                </v-card-title>
                            </v-col>
                            <v-col cols="5">
                                <v-card-text v-if="income.amount">
                                    Υπόλοιπο: {{ income.amount - totalInstallmentAmount }} €
                                </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-card-text>
                                    Πληρωτέο: {{ totalInstallmentAmount }} €
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <v-timeline side="end" dense class="my-2">
                            <v-timeline-item
                                v-for="(installment, index) in income.incomeInfo.installments"
                                :key="installment.installmentDate"
                                color="green"
                                small
                            >
                                <div class="installment">
                                    <div class="font-weight-normal">
                                        <strong>{{ getDateFormated(installment.installmentDate) }}</strong>
                                        <v-icon class="mx-2">
                                            mdi-arrow-bottom-left
                                        </v-icon>
                                        Ποσό: {{ installment.amount }} € /
                                        Περιγραφή: {{ installment.description }} /
                                        Μέθοδος πληρωμής: {{ installment.paymentMethod }}
                                    </div>
                                    <v-btn
                                        class="mx-5 my-2"
                                        dark
                                        max-width="20"
                                        max-height="20"
                                        fab
                                        color="red"
                                        @click="removeInstallment(index)"
                                    > <v-icon>mdi-minus</v-icon></v-btn>
                                </div>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" :disabled="shouldDisableSubmit()" :loading="loading" large color="black" @click="onSubmit">
                Καταχώριση
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Ακύρωση
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import incomeService from '@/services/income'
    import Alert from '@/components/Alert'
    import categoryService from '@/services/category'
    import chargeUnitService from '@/services/chargeUnit'
    import momentTimezone from 'moment-timezone'

    export default {
        name: 'IncomeForm',
        components: { Alert },
        data: () => {
            return {
                menu: false,
                menu2: false,
                menu3: false,
                menu4: false,
                installmentDate: null,
                installmentAmount: 0,
                installmentDialog: false,
                installmentDescription: '',
                installmentPaymentMethod: null,
                installmentSpecs: {
                    cashOnName: null,
                    checkDate: null,
                    checkExpirationDate: null,
                    checkNumber: null,
                    checkBank: null
                },
                invoiceType: [
                    { displayValue: 'ΤΙΜΟΛΟΓΙΟ ΠΑΡΟΧΗΣ ΥΠΗΡΕΣΙΩΝ', value: 'TPY' },
                    { displayValue: 'ΑΠΛΟ ΤΙΜΟΛΟΓΙΟ', value: 'INVOICE' },
                    { displayValue: 'ΤΙΜΟΛΟΓΙΟ/ΔΕΛΤΙΟ ΑΠΟΣΤΟΛΗΣ', value: 'TDA' },
                    { displayValue: 'ΠΙΣΤΩΤΙΚΟ ΤΙΜΟΛΟΓΙΟ', value: 'PT' }
                ],
                status: [
                    { displayValue: 'ΕΚΚΡΕΜΕΙ', value: 'PENDING' },
                    { displayValue: 'ΠΛΗΡΩΜΕΝΟ', value: 'PAID' },
                    { displayValue: 'ΑΚΥΡΩΜΕΝΟ', value: 'CANCELED' }
                ],
                paymentMethod: [
                    { displayValue: 'ΜΕΤΡΗΤΑ', value: 'CASH' },
                    { displayValue: 'ΕΠΙΤΑΓΗ', value: 'CHECK' },
                    { displayValue: 'ΤΡΑΠΕΖΑ', value: 'BANK' },
                    { displayValue: 'ΚΟΥΠΟΝΙΑ', value: 'COUPONS' }
                ],
                showAlert: false,
                message: null,
                type: null,
                loading: false,
                mode: null,
                level: 1,
                cuLevel: 1,
                categories: [],
                chargeUnits: [],
                selectedCategoriesByLevel: { 1: {} },
                selectedChargeUnitsByLevel: { 1: {} },
                income: {
                    chargeUnit: {},
                    category: {},
                    incomeInfo: {
                        installments: []
                    },
                    fpa: 0,
                    withholding: 0
                },
                readOnly: false,
                clearable: true,
                rules: [
                    v => !!v || 'Field is required'
                ],
                numberRules: [
                    v => (!!v && v > 0) || 'Field is required and > 0'
                ],
                banks: ['ALPHA', 'ΠΕΙΡΑΙΩΣ', 'ΕΘΝΙΚΗ', 'EUROBANK']
            }
        },
        watch: {
            'income.invoiceType': {
                handler() {
                    if (!this.income.incomeInfo) {
                        this.income.incomeInfo = {}
                    }
                }
            }
        },
        computed: {
            totalInstallmentAmount() {
                let total = 0
                const installments = this.income.incomeInfo.installments
                if (!installments) {
                    return total
                }
                for (const installment of installments) {
                    total += installment.amount
                }
                return total
            },
            hasInstallments() {
                return this.income.incomeInfo?.installments && this.income.incomeInfo?.installments.length > 0
            },
            computedIncomeDate() {
                return this.income.incomeDate ? momentTimezone(this.income.incomeDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCheckDate() {
                return this.income.incomeInfo.checkDate ? momentTimezone(this.income.incomeInfo.checkDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCheckExpirationDate() {
                return this.income.incomeInfo.checkExpirationDate ? momentTimezone(this.income.incomeInfo.checkExpirationDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedInstallmentDate() {
                return this.installmentDate ? momentTimezone(this.installmentDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedCleanMoney() {
                return this.income.amount / (1 + this.income.fpa / 100)
            },
            computedFinalMoney() {
                return this.income.amount - ((this.income.withholding / 100) * this.computedCleanMoney)
            },
            computedInstallmentCheckDate() {
                return this.installmentSpecs.checkDate ? momentTimezone(this.installmentSpecs.checkDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            },
            computedInstallmentCheckExpirationDate() {
                return this.installmentSpecs.checkExpirationDate ? momentTimezone(this.installmentSpecs.checkExpirationDate).tz('Europe/Athens').format('DD/MM/YYYY') : ''
            }
        },
        created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.incomeId = this.$route.params.incomeId

            this.getCategories()
            this.getChargeUnits()

            if (this.$route.params.incomeId) {
                incomeService.getIncome(this.$route.params.incomeId)
                    .then(resp => {
                        this.income = resp.data
                        this.level = this.income.category.level
                        this.cuLevel = this.income.chargeUnit.level
                        this.income.incomeDate = momentTimezone(this.income.incomeDate).tz('Europe/Athens').format('YYYY-MM-DD').toString()
                        this.initSelectedCategoriesByLevel()
                        this.initSelectedChargeUnitsByLevel()
                        if (this.income.paymentMethod === 'CASH') {
                            if (!this.income.incomeInfo) {
                                this.income.incomeInfo = {}
                                this.income.incomeInfo.cashOnName = ''
                            }
                        }
                    })
            } else if (this.$route.query.copy) {
                let data = this.$store.getters.getTempIncome
                this.income = data.income
                this.selectedCategoriesByLevel = data.selectedCategoriesByLevel
                this.selectedChargeUnitsByLevel = data.selectedChargeUnitsByLevel
                this.level = data.level
                this.cuLevel = data.cuLevel
            }
        },
        methods: {
            createCopy() {
                this.$store.dispatch('setTempIncome', {
                    income: this.income,
                    bankIncome: this.bankIncome,
                    addBankIncome: this.addBankIncome,
                    selectedCategoriesByLevel: this.selectedCategoriesByLevel,
                    selectedChargeUnitsByLevel: this.selectedChargeUnitsByLevel,
                    level: this.level,
                    cuLevel: this.cuLevel
                })
                this.$router.push({
                    path: '/income/create',
                    params: { mode: 'create' },
                    query: { page: this.$route.query.page, size: this.$route.query.size, copy: true }
                })
            },
            getFilteredChargeUnits() {
                return this.chargeUnits.filter((element) => {
                    if (element.level === this.cuLevel) {
                        if (element.parent === null) {
                            return element
                        } else {
                            if (element.parent.id === this.selectedChargeUnitsByLevel[this.cuLevel - 1].id) {
                                return element
                            }
                        }
                    }
                })
            },
            getFilteredCategories() {
                return this.categories.filter((element) => {
                    if (element.level === this.level) {
                        if (element.parent === null) {
                            return element
                        } else {
                            if (element.parent.id === this.selectedCategoriesByLevel[this.level - 1].id) {
                                return element
                            }
                        }
                    }
                })
            },
            resetInstallmentSpecs() {
                this.installmentSpecs = {
                    cashOnName: null,
                    checkDate: null,
                    checkExpirationDate: null,
                    checkNumber: null,
                    checkBank: null
                }
            },
            getDateFormated(date) {
                return momentTimezone(date).tz('Europe/Athens').format('DD/MM/YYYY').toString()
            },
            initSelectedChargeUnitsByLevel() {
                let temp = this.income.chargeUnit
                for (let index = this.income.chargeUnit.level; index > 0; index--) {
                    this.selectedChargeUnitsByLevel[index] = temp
                    temp = temp.parent
                }
            },
            initSelectedCategoriesByLevel() {
                let temp = this.income.category
                for (let index = this.income.category.level; index > 0; index--) {
                    this.selectedCategoriesByLevel[index] = temp
                    temp = temp.parent
                }
            },
            clearCategoryPath() {
                this.level = 1
                this.selectedCategoriesByLevel = { 1: {} }
                this.income.category = {}
            },
            clearChargeUnitPath() {
                this.cuLevel = 1
                this.selectedChargeUnitsByLevel = { 1: {} }
                this.income.chargeUnit = {}
            },
            getCategoryPath() {
                let path = ''
                for (let index = this.level - 1; index > 0; index--) {
                    path += `${index === this.level - 1 ? '' : '/'}${this.selectedCategoriesByLevel[index].title}(${this.selectedCategoriesByLevel[index].code})`
                }
                return path
            },
            getChargeUnitPath() {
                let path = ''
                for (let index = this.cuLevel - 1; index > 0; index--) {
                    path += `${index === this.cuLevel - 1 ? '' : '/'}${this.selectedChargeUnitsByLevel[index].title}(${this.selectedChargeUnitsByLevel[index].code})`
                }
                return path
            },
            setLevel() {
                if (this.selectedCategoriesByLevel[this.level]) {
                    this.income.category = this.selectedCategoriesByLevel[this.level]
                    this.level += 1
                }
            },
            setCuLevel() {
                if (this.selectedChargeUnitsByLevel[this.cuLevel]) {
                    this.income.chargeUnit = this.selectedChargeUnitsByLevel[this.cuLevel]
                    this.cuLevel += 1
                }
            },
            getCategories() {
                categoryService.getAllCategories().then(resp => {
                    this.categories = resp.data
                })
            },
            getChargeUnits() {
                chargeUnitService.getAllChargeUnits().then(resp => {
                    this.chargeUnits = resp.data
                })
            },
            removeInstallment(installmentIndex) {
                this.income.incomeInfo.installments.splice(installmentIndex, 1)
            },
            onInstallmentSubmit() {
                if (!this.income.incomeInfo) {
                    this.income.incomeInfo = {}
                    this.income.incomeInfo.installments = []
                }
                const installmentOnDateIndex = this.income.incomeInfo.installments.findIndex(installment => this.installmentDate === installment.installmentDate)
                if (installmentOnDateIndex >= 0) {
                    this.income.incomeInfo.installments[installmentOnDateIndex].amount += parseFloat(this.installmentAmount)
                } else {
                    this.income.incomeInfo.installments.push({
                        installmentDate: momentTimezone(this.installmentDate).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString(),
                        amount: parseFloat(this.installmentAmount),
                        description: this.installmentDescription,
                        paymentMethod: this.installmentPaymentMethod,
                        specs: this.installmentSpecs
                    })
                }
                this.sortInstallmentsByDate()
                this.installmentDate = null
                this.installmentAmount = null
                this.installmentDialog = false
                this.installmentDescription = ''
                this.installmentPaymentMethod = null
                this.installmentSpecs = {}
            },
            clearIncomeInfoFields() {
                if (!this.income.incomeInfo) {
                    this.income.incomeInfo = {}
                    delete this.income.incomeInfo
                } else {
                    if (this.income.paymentMethod !== 'CASH') {
                        this.income.incomeInfo.cashOnName = null
                    }
                    if (this.income.paymentMethod !== 'CHECK') {
                        this.income.incomeInfo.checkDate = null
                        this.income.incomeInfo.checkExpirationDate = null
                        this.income.incomeInfo.checkNumber = null
                        this.income.incomeInfo.checkBank = null
                    }
                    if (!this.income.invoiceType) {
                        this.income.incomeInfo.invoiceId = null
                        this.income.incomeInfo.invoiceNumber = null
                    }
                    if (!this.hasInstallments) {
                        this.income.incomeInfo.installments = null
                    }
                }
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return !(this.income.incomeDate && this.income.status && this.income.amount && this.income.category && this.income.paymentMethod)
            },
            createIncome() {
                this.loading = true
                incomeService.createIncome(this.income).then(incomeResponse => {
                    if (incomeResponse.id) {
                        this.incomeId = incomeResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/income?page=${this.$route.query.page}&size=${this.$route.query.size}`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editIncome() {
                this.loading = true
                incomeService.updateIncome(this.income.id, this.income).then((response) => {
                    this.loading = false
                    this.$router.push(`/income?page=${this.$route.query.page}&size=${this.$route.query.size}`)
                }).catch((error) => {
                    this.setAlert(error.response.data.message, 'error')
                    this.loading = false
                })
            },
            sortInstallmentsByDate() {
                if (!this.income.incomeInfo.installments) {
                    return
                }
                this.income.incomeInfo.installments.sort(function(a, b) {
                    if (a.installmentDate < b.installmentDate) return -1
                    if (a.installmentDate > b.installmentDate) return 1
                    return 0
                })
            },
            async onSubmit() {
                this.income.incomeDate = momentTimezone(this.income.incomeDate).tz('Europe/Athens').startOf('day').utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]').toString()
                this.clearIncomeInfoFields()
                if (this.mode === 'edit') {
                    this.editIncome()
                } else if (this.mode === 'create') {
                    this.createIncome()
                }
            }
        }

    }
</script>
<style scoped>

.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.installment {
    display: flex;
    align-items: center;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
