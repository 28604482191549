import api from '@/utils/api'

export default {
    getIncomes(page, size, query, sort) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`income` + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : '') + (sort ? `&sort=${encodeURIComponent(sort)}` : `&sort=${encodeURIComponent('[id, DESC]')}`))
    },
    getIncomesCsv(query) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        return api.get(`income/report` + (filter ? `?filter=${encodeURIComponent('{' + filter + '}')}` : ''))
    },
    getIncome(id) {
        return api.get(`income/${id}`)
    },

    createIncome(data) {
        return api.post('income', data)
    },

    updateIncome(id, data) {
        return api.put(`income/${id}`, data)
    },

    deleteIncome(id) {
        return api.delete(`income/${id}`)
    },

    getTotals(query) {
        let filter = null
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        return api.get(`income/sums${filter ? `?filter=${encodeURIComponent('{' + filter + '}')}` : ''}`)
    }
}
